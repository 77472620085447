// Typography
$font-size-base: 16px;
$line-height-base: 1.5;
$headings-font-weight: 700;

// Layout
$grid-gutter-width: 30px;
$grid-gutter-height: $grid-gutter-width;
$container-large-desktop: (1170px + $grid-gutter-width);
$screen-lg-min: 1200px;
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;
$minimax-m-content-padding: 55px;
$close-text-shadow: none;

// Map
$m-map-height: 100%;

// Flex layout module
$m-flex-layout-content-width: 80%;
$m-flex-layout-aside-width: 20%;

// Select
$m-select-padding: 6px 30px 6px 12px;

// Floating Action Button
$m-floating-action-btn-font-size: 34px;

// Group module
$m-group-nav-link-padding: 11px;

// Item module
$m-item-gutter-base: 15px;
$m-item-title-font-size: 1rem;
$m-item-description-font-size: 0.875rem;

// List module
$m-list-title-font-size: 1.5rem;
$m-list-title-text-vertical-align: text-bottom;

// Menu description
$m-menu-description-margin: 6px 0;
$m-menu-description-padding: 50px;
$m-menu-description-text-align: left;

// Colors
$brand-success: #80bc68;
$indicator-color: #eef2f3;
$separator-color: $indicator-color;

// Buttons
$btn-default-bg: transparent;
$btn-text-transform: uppercase;
$btn-border-radius-base: $border-radius-base;
$btn-padding-base-vertical: 12px;
$btn-padding-base-horizontal: 27px;
$btn-padding-large-vertical: 12px;
$btn-padding-large-horizontal: 40px;

// Forms
$form-group-margin-bottom: 25px;
$input-border: rgba(#000, 0.1);
$input-box-shadow: none;
$input-height-base: 43px;

// Card
$card-background: transparent;
$card-margin-bottom: 0;
$card-padding: 0;
$card-box-shadow: none;

// Restaurant Selection
$m-restaurants-card-background-color: var(--color-body-bg);

// Fulfillment Widget
$m-fulfillment-widget-margin-block: 0;

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'base/mixins/m-aside-info';
@import 'modules/m-backdrop';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';
@import 'modules/m-page-subheader';

// MiniMax
@import 'modules/minimax/m-header';
@import 'modules/minimax/m-nav';
@import 'modules/minimax/m-brand';
@import 'modules/minimax/m-cart-info';
@import 'modules/minimax/m-icon-group';
@import 'modules/minimax/m-hero';
@import 'modules/minimax/m-content-box';
@import 'modules/minimax/m-indicators';
@import 'modules/minimax/m-page-header';
@import 'modules/minimax/m-testimonials';
@import 'modules/minimax/m-special-offer';
@import 'modules/minimax/m-promotions';
@import 'modules/minimax/m-steps';
@import 'modules/minimax/m-teasers';
@import 'modules/minimax/m-table';
@import 'modules/minimax/m-box-teasers';
@import 'modules/minimax/m-contact';
@import 'modules/minimax/m-checkout';
@import 'modules/minimax/m-footer';
@import 'modules/minimax/m-aside-info';
@import 'modules/minimax/m-minimax-info';

html {
  font-size: $font-size-base;
}

body {
  font-size: 1rem;
}

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

button,
a {
  transition: color, background-color, opacity, border-color;
  transition-duration: $base-duration;
}

.btn-default {
  border: 2px solid;

  &:hover {
    //opacity: $hover-opacity;
  }
}

.btn-link {
  text-transform: none;
  font-family: inherit;
}

.dropdown-menu {
  animation: fadeIn $base-duration;
}

.text-success,
.icon-ok {
  color: $brand-success;
}

.m-block-action {
  margin: 0;

  &__btn {
    display: block;
    padding: 15px;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.m-container {
  @include container-fixed($grid-gutter-width);
  max-width: $container-large-desktop;

  &--tight {
    padding-right: 15px;
    padding-left: 15px;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-right: 60px;
      padding-left: 60px;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 100px;
      padding-left: 100px;
    }
  }
}

.content-content {
  > .form-inline {
    margin-bottom: $base-spacing;
  }
}

.m-floating-action {
  &--cart-toggle {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: none; // hide it above mobile viewport, because of the sticky header
    }
  }
}

.m-content {
  padding-top: $minimax-m-content-padding;
  padding-bottom: $minimax-m-content-padding;
}

.content-header {
  margin-bottom: 15px;

  &--primary {
    margin-top: -$minimax-m-content-padding;
    transform: translateY(-50%);
  }

  h1,
  &__title {
    margin-top: 0.625rem;
    margin-bottom: 0.125rem;
    font-size: 2.625rem;

    &.h2 {
      margin-top: 0; // h2 class means that this is shared partial from old themes and it doesn't have description
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 4.5rem;
    }
  }

  &__description {
    font-size: 1.125rem;
  }
}

// List module
.m-list {
  &__title {
    font-weight: $font-weight-base;
  }

  &--minimax-promotions {
    .table {
      margin-bottom: 0;

      th {
        border-bottom: 0;
      }
    }
  }

  &--grid {
    .m-list {
      &__item {
        @media screen and (min-width: $screen-xs-min) {
          padding: $grid-gutter-width;
        }

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-bottom: -1px;
          border-right: $m-list-item-border-top;
          border-bottom: $m-list-item-border-top;

          &:nth-child(2n) {
            @media screen and (max-width: $screen-sm-max) {
              border-right: 0;
            }
          }
        }

        @media screen and (min-width: $screen-md-min) {
          &:nth-child(3n) {
            border-right: 0;
          }
        }
      }
    }
  }

  &--grid-md-2 {
    .m-list {
      &__item {
        @media screen and (min-width: $screen-xs-min) {
          padding: $grid-gutter-width;
        }

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-bottom: -1px;
          border-right: $m-list-item-border-top;
          border-bottom: $m-list-item-border-top;

          &:nth-child(2n) {
            border-right: 0;
          }
        }
      }
    }
  }
}

// Item module
.m-item {
  &__title {
    font-weight: $font-weight-bold;
  }

  &__price {
    font-size: 0.9375rem;
    font-weight: $font-weight-bold;
  }
}

// Group module
.m-group {
  &__header {
    font-size: $m-list-title-font-size;
    font-weight: $font-weight-base;
  }
}

// Group nav module
.m-group-nav {
  &__title {
    margin-bottom: floor($grid-gutter-height / 2);
    padding-top: $m-group-nav-link-padding;
    padding-bottom: $m-group-nav-link-padding;
    font-size: 1.5rem;
  }
}

.item-counter,
.m-item__badge {
  @extend .badge;
}
