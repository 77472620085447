.m-minimax-info {
  font-weight: $font-weight-bold;
  line-height: 1.8;

  &__header {
    position: relative;
    margin: 0 0 5px;
    font-size: 0.8125rem;
    font-weight: $font-weight-bold;

    svg {
      fill: currentColor;
    }
  }

  &__header-icon {
    position: absolute;
    left: -10px;
    transform: translateX(-100%) translateY(-20%);
  }

  &__list-item {
    margin-bottom: 25px;
    padding-left: 25px;
  }

  .m-brand__logo-img {
    max-height: 180px;
  }
}
