.m-box-teasers {
  margin-right: floor($grid-gutter-width / -2);
  margin-left: floor($grid-gutter-width / -2);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;


  @media screen and (min-width: $screen-xlg-min) {
    margin-right: -23px;
    margin-left: -23px;
  }

  &__item {
    margin-top: 15px;
    margin-bottom: 31px;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    max-width: floor(356 + $grid-gutter-width);

    @media screen and (min-width: $screen-xlg-min) {
      max-width: 402px;
      padding-right: 23px;
      padding-left: 23px;
    }
  }

  &__content {
    padding: 35px;
  }

  &__featured {
    position: relative;
    top: floor($grid-gutter-width / -2);
    left: floor($grid-gutter-width / -2);
    margin-bottom: floor($grid-gutter-width / -2);
  }

  &__title {
    margin-top: 0;
    margin-bottom: floor($grid-gutter-width / 2);
    font-size: 1.25rem;
    line-height: 1.3;
  }

  &__section {
    margin-bottom: 25px;
  }

  &__action {
    margin: 0;
  }

  &__special-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.875rem;

    li {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}
