.m-hero {
  &__container {
    @include container-fixed();
    position: relative;
    max-width: $container-large-desktop;
    padding-right: 60px;
    padding-left: 0;

    .m-hero--wide & {
      padding-right: 0;
    }
  }

  &__inner {
    position: relative;
    overflow: hidden;
    margin-top: -20px;
    right: -20px;
    width: 100%;

    .m-hero--wide & {
      margin-top: 0;
      right: 0;
    }
  }

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    display: block;
    width: 100%;
  }

  &__item-inner {
    width: 100%;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: center;
    }
  }

  &__featured {
    position: relative;
    display: inline-block;
    height: 100%;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      @include position(absolute, null 0 0 0);
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      flex: 0 0 auto;
      max-width: 75%;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-width: none;
    }

    @supports (object-position: 50% 50%) {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        top: 0;

        .m-hero__slide-img {
          height: 100%;
        }

        .m-hero__image {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--bg {
      position: relative;
      width: 100%;
      max-width: none;
    }
  }

  &__slide-content {
    // Some values can be different depending on theme settings, see more in theme.scss.liquid
    margin: ceil($grid-gutter-width * 3) $grid-gutter-width $grid-gutter-width;
    padding: $grid-gutter-width;
    position: relative;
    z-index: $zindex-ui;
    height: 100%;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 100%;
    }

    @media screen and (min-width: $screen-md-min) {
      padding: 65px 50px;
    }

    &--overlay.m-hero__slide-content { // override theme.scss.liquid
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 100px;
      min-height: 300px;

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-top: 200px;
        min-height: 601px;
      }
    }
  }

  &__slide-img {
    border: 20px solid;

    @media screen and (min-width: $grid-float-breakpoint) {
      border-top-width: 0;
    }

    &--bg {
      @include position(absolute, 0 0 0 0);
      border: none;
    }
  }

  &__image {
    &--cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__indicators {
    @include position(absolute, 0 20px 90px null);
    display: flex;
    align-items: center;
    z-index: $zindex-ui;

    @media screen and (min-width: $screen-lg-min) {
      right: 5px;
    }

    &:before {
      @include position(absolute, null 50% 0 null);
      height: 2000px;
      width: 2px;
      background-color: $separator-color;
      content: " ";
      z-index: floor($zindex-ui - 5);
    }

    .m-indicators {
      padding-top: 15px;
      padding-bottom: 15px;
      position: relative;
      z-index: $zindex-ui;

      &__list {
        margin-left: -2px;
      }
    }

    &--horizontal {
      top: auto;
      left: 0;
      right: 0;
      bottom: 20px;
      justify-content: center;

      &:before {
        display: none;
      }
    }
  }
}
