.m-testimonials {
  &--section {
    padding-top: 130px;
    padding-bottom: 60px;
    overflow: hidden;
  }

  &__inner {
    &--ornament {
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__ornament {
    @include position(absolute, 0 0 null null);
    transform: translateX(50%) translateY(-20%);

    &:first-of-type {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: translateX(-65%);
    }
  }

  &__list {
    position: relative;

    @media screen and (min-width: $grid-float-breakpoint) {
      column-count: 2;
      column-gap: $grid-gutter-width;
    }
  }

  &__list-item {
    display: block;
    width: 100%;
    break-inside: avoid;
  }

  &__item {
    position: relative;
    margin-bottom: $grid-gutter-width;
    padding: 50px 40px;
    overflow: hidden;
  }

  &__item-inner {
    position: relative;
  }

  &__rating {
    margin-bottom: 40px;
    font-size: 1.2rem;
    color: #e6c967;
  }

  &__content {
    font-size: 1.875rem;
  }

  &__indicator {
    @include position(absolute, 70px null null 0);
    font-size: 6rem;
    color: $indicator-color;
  }

  &__author {
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  &__info {
    margin-top: 22px;
    padding-top: 22px;
    border-top: 1px solid #e6eaeb;
  }

  &__action {
    margin-top: $grid-gutter-width;
    font-weight: $font-weight-bold;
  }

  &__action-link {
    color: inherit;
  }

  &__annotation {
    position: relative;
    margin: 55px -32px -43px;
    padding: 20px 32px;

    &:before {
      @include position(absolute, -20px null null 33px);
      border-right: 10px solid transparent;
      border-bottom: 20px solid black;
      content: " ";
    }
  }
}
