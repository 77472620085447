.m-footer {
  &__main {
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex;

    @media screen and (max-width: $screen-md-max) {
      flex-wrap: wrap;
    }
  }

  &__content {
    margin-bottom: 40px;
  }

  &__col {
    margin-bottom: 40px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-grow: 1;

    .m-brand {
      max-width: 200px;
      margin-right: auto;
      margin-left: auto;
    }

    .m-restaurant-hours__item {
      &:first-child {
        padding-top: 0;
      }
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 13px;
    font-weight: $font-weight-bold;
  }

  &__additional-info {
    padding-bottom: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-grow: 100;

    > * {
      padding: 35px $grid-gutter-width 0;
    }
  }

  &__meta {
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    .powered-by {
      display: inline-block;
      margin-bottom: ($line-height-computed / 2);
    }
  }

  &__copyright {
    margin-right: floor($grid-gutter-width / 2);

    &:not(:first-line) {
      font-size: 11px;
    }
  }
}
