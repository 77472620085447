.m-table {
  //width: 100%;
  font-size: 0.875rem;

  &__heading {
    position: relative;
    margin: 0 0 1em;
    padding-bottom: 1em;
    font-weight: $font-weight-bold;
    font-size: 1rem;

    &:after {
      @include position(absolute, null null 0 0);
      width: 22px;
      height: 2px;
      content: " ";
    }
  }

  &__tr {
    &:first-child {
      > .m-table__th,
      > .m-table__td {
        padding-top: 0;
      }
    }
  }

  &__th {
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  &__td {
    @include text-break();
  }

  &__th,
  &__td {
    padding: $table-cell-padding;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--slim {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &--flat {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.u-va-middle {
      vertical-align: middle;
    }
  }

  &--regular {
    font-size: inherit;

    .m-table__th,
    .m-table__td {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}
