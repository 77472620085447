.m-indicators {
  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  li, // same as .m-indicators__list-item (required by Splide pagination)
  &__list-item {
    display: inline-block;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 7px;
    min-height: 35px;
    vertical-align: middle;

    &[aria-expanded="true"], // support for Bootstrap tabs
    &.is-active {
      &:before {
        background-color: #252525;
        width: 16px;
        height: 16px;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $indicator-color;
      transition: background-color, width, height;
      transition-duration: $base-duration;
      text-indent: -999em;
      overflow: hidden;
    }
  }

  &--vertical {
    .m-indicators__dots {
      flex-direction: column;
    }
  }

  @at-root .m-hero__indicators--horizontal .m-indicators {
    padding-top: 0;
    padding-bottom: 0;
  }
}
