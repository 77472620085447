.m-special-offer {
  &__wrapper {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
    }
  }

  &__content {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    align-items: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      max-width: 60vw;
      padding-top: 90px;
      padding-right: 60px;
      padding-bottom: 60px;
    }
  }

  &__media {
    display: flex;
    align-items: center;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }

    @media screen and (min-width: $screen-lg-min) {
      flex-shrink: 0;
    }
  }

  &__picture {
    @supports (object-position: 50% 50%) {
      display: block;
      height: 100%;

      .m-special-offer__image {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
