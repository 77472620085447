.m-content-box {
  &__category {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 1.5rem;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 30px;

    @media screen and (min-width: $screen-md-min) {
      font-size: 2.625rem;

      &--hero {
        font-size: 3.75rem;
      }
    }
  }

  &__description {
    margin-bottom: 30px;
    font-size: 1.25rem;

    &--hero {
      font-size: 1.5rem;
    }
  }

  &__action {
    margin-bottom: 0;
  }

  &__info {
    margin-top: 30px;
    font-size: 0.8125rem;
    opacity: $hover-opacity;

    &:empty {
      display: none;
    }
  }
}
