.m-page-header {
  position: relative;

  &__title {
    margin-top: 0;

    &--slim {
      margin-bottom: 35px;
      font-size: 1.5rem;
    }
  }
}
