@include m-aside-info("m-aside-info", $m-aside-info-toggle-padding: 40px, $m-aside-info-trigger-padding: 0.5em);

.m-aside-info {
  &__trigger {
    &:hover,
    &:focus,
    &.is-active {
      color: #fff;
    }
  }

  &__header {
    margin-bottom: ($base-spacing * 2);
  }

  &__footer {
    margin-top: ($base-spacing * 2);
  }
}

.m-aside-info-backdrop {
  &,
  &.in {
    opacity: 1;
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    border: 20px solid;
  }
}
