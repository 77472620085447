.m-steps {
  &__wrapper {
    padding: 30px 30px 10px;
    border: 30px solid;

    @media screen and (min-width: $screen-xs-min) {
      padding: 60px 50px 40px;
    }

    @media screen and (min-width: $screen-xlg-min) {
      margin-right: -80px;
      margin-left: -80px;
    }
  }

  &__list {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    counter-reset: minimax-steps-counter;

    @media screen and (max-width: $screen-md-max) {
      flex-wrap: wrap;
    }

    &--vertical {
      flex-direction: column;
    }
  }

  &__list-item {
    position: relative;
    margin-bottom: 20px;
    min-width: 250px;
    flex: 1 1 0;

    @media screen and (min-width: $screen-xs-min) {
      display: flex;

      &:before {
        position: absolute;
        top: -17px;
        left: 70px;
        font-size: 5rem;
        font-weight: $headings-font-weight;
        line-height: 1;
        transform: translateX(-50%);
        counter-increment: minimax-steps-counter;
        content: counter(minimax-steps-counter, decimal);

        @media screen and (min-width: $grid-float-breakpoint) {
          font-size: 9rem;
        }
      }

      .svg-icon-shopping-cart {
        margin-left: -15px;
        margin-right: -4px;
      }
    }

    &--simple {
      &:before {
        display: none;
      }
    }
  }

  &__icon {
    position: relative;
  }

  &__item {
    position: relative;

    @media screen and (max-width: $screen-md-max) {
      padding-right: 20px;
    }

    @media screen and (min-width: $screen-xs-min) {
      padding-left: 20px;
    }

    .m-steps__list--vertical & {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__next-indicator {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    color: #c5c9ca;

    @media screen and (max-width: $screen-md-max) {
      display: none;
    }

    &--vertical {
      display: block;

      & > * {
        transform: rotate(90deg);
      }
    }
  }

  &__title {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);
    font-size: 1.25rem;
  }

  &__section {
    padding-top: $minimax-m-content-padding + $base-spacing;
    padding-bottom: $minimax-m-content-padding;
  }
}
