@mixin m-aside-info(
  $block-name, // required BEM block name
  $m-aside-info-bg-color: white,
  $m-aside-info-color: black,
  $m-aside-info-trigger-zindex: $zindex-modal,
  $m-aside-info-trigger-padding: 0.7em 2em,
  $m-aside-info-trigger-bg-color: black,
  $m-aside-info-trigger-color: white,
  $m-aside-info-toggle-padding: $grid-gutter-width
) {
  .#{$block-name} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $m-aside-info-trigger-zindex;
    width: 320px;
    max-width: 100%;
    color: $m-aside-info-color;
    transform: translate3d(100%, 0, 0);
    transition: transform $base-duration;
    background-color: $m-aside-info-bg-color;

    &.is-active {
      transform: translate3d(0, 0, 0);

      .#{$block-name}__toggle {
        visibility: visible;
      }
    }

    &__trigger {
      position: absolute;
      top: 270px;
      left: 0;
      display: inline-block;
      padding: $m-aside-info-trigger-padding;
      color: $m-aside-info-trigger-color;
      background-color: $m-aside-info-trigger-bg-color;
      transform: translateX(-100%) translateY(-50%);
      transform-origin: top left;
      white-space: nowrap;

      &--rotate {
        transform: rotate(-90deg) translateX(-50%) translateY(-100%);
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &__toggle {
      padding: $m-aside-info-toggle-padding;
      overflow-y: scroll;
      max-height: 100%;
      visibility: hidden;
      transition: visibility $base-duration;
    }
  }
}


