.m-icon-group {
  &__list {
    @include list-unstyled;
  }

  &__list-item {
    display: inline-block;
    margin-right: map-get($spacers, 1);
    margin-left: map-get($spacers, 1);

    .m-footer__copyright & {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__item {
    &--rounded {
      display: block;
      width: 27px;
      height: 27px;
      text-align: center;
      line-height: 27px;
      border-radius: 50%;
      font-size: 14px;
    }
  }
}
