.m-header {
  margin-bottom: 20px;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-bottom: 10px;
  }

  a {
    color: inherit;
  }

  &__container {
    @include container-fixed();
    @include clearfix;
    max-width: $container-large-desktop;
    padding-right: ceil($grid-gutter-width / 2) + 30px;

    &--hero {
      position: relative;
      padding-right: 60px;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: flex; // Separate flexbox layout because of the IE11 flex layout bug.
      align-items: center;

      &:not(.m-header__container--hero) {
        padding-right: ceil($grid-gutter-width / 2);
      }

      .m-header__main {
        flex-grow: 1;
      }

      .m-header__meta {
        margin-bottom: 0;
      }

      .m-header__meta-inner {
        justify-content: flex-end;
      }

      .m-header__meta-nav {
        flex-wrap: nowrap;
      }

      .m-header__brand {
        max-width: 50vw;

        &:not(.m-header__brand--hero) {
          padding-left: 0;
        }
      }
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-left: 60px;
    }
  }

  &__brand {
    margin-top: 16px;
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;

    @media screen and (min-width: $grid-float-breakpoint) {
      float: left; // Use float instead of flexbox to prevent IE11 layout bug.
      width: 25%;
    }

    .m-brand__logo {
      max-height: 120px;
    }

    &--hero {
      @media screen and (min-width: $screen-md-min) {
        @include position(absolute, null null 0 0);
        transform: translateY(50%);
        z-index: $zindex-ui;

        .m-brand__logo {
          margin-left: 0;
          max-height: none;
        }
      }
    }
  }

  &__main {
    text-transform: uppercase;

    @media screen and (min-width: $grid-float-breakpoint) {
      float: right; // Use float instead of flexbox to prevent IE11 layout bug.
      width: calc(75% - 30px);
    }

    @media screen and (min-width: $screen-lg-min) {
      min-width: 799px;
    }
  }

  &__meta {
    margin-bottom: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 0.875rem;
    border-bottom: 2px solid #f0f1f3;
  }

  &__base {
    margin-top: 24px;
    margin-bottom: 24px;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  &__meta-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__meta-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__base-content {
    display: flex;
    align-items: center;

    @at-root .m-header__sticky & {
      justify-content: flex-end;
    }

    @media screen and (max-width: $screen-md-max) {
      flex-wrap: wrap;
    }
  }

  &__nav {
    margin-bottom: 16px;
    font-size: 1.0625rem;
    flex-grow: 1;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-right: 16px;

      @at-root .m-header__sticky & {
        margin-bottom: 0;
        flex-grow: 0;
      }
    }
  }

  &__info {
    margin-bottom: 16px;

    @at-root .m-header__sticky & {
      margin-bottom: 0;
    }
  }

  &__info-list {
    margin: 0 -10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    list-style: none;

    > li {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__restaurant-status {
    span {
      color: $brand-success;
    }
  }

  &__sticky {
    @include position(fixed, 0 0 null 0);
    z-index: $zindex-navbar-fixed;
    padding: 6px 0;
    border-bottom: 1px solid;
    background: white;
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
    transition: transform $base-duration, visibility $base-duration;

    .l-navbar-affixed & {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }
  }

  &--spacing {
    margin-bottom: 50px;
  }

  &--menu {
    margin-bottom: 0;
  }

  .m-nav-toggle {
    margin-left: 15px;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  .mobile-navigation {
    font-size: 1rem;
    text-transform: none;
  }
}
