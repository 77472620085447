.m-contact {
  &__col-data {
    margin-bottom: 10px;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
    }
  }

  &__col-info {
    padding: 45px;
    flex-basis: 50%;
    flex-grow: 1;

    &--map {
      padding: 0;
      display: flex;
    }
  }

  &__info {
    padding: 15px;
  }

  &__heading {
    margin-bottom: $line-height-computed;
  }

  &__subtitle {
    margin: 0;
    font-size: 1.1875rem;
  }
}
