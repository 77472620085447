// Checkout module doesn't follow BEM guidelines because of the limited control over markup and shared code for all themes.

.m-checkout {
  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-md-min) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  .column-form {
    margin-right: $grid-gutter-width;
    padding: 40px;
    width: 100%;
    order: 2;

    @media screen and (min-width: $screen-md-min) {
      margin-right: floor($grid-gutter-width * 2);
      width: 65%;
      flex: 1 1 65%;
    }

    @media screen and (min-width: $screen-xlg-min) {
      margin-right: 30px;
    }
  }

  .column-summary {
    padding: 40px;
    width: 100%;
    order: 1;

    @media screen and (min-width: $screen-md-min) {
      width: 35%;
      max-width: 668px;
      flex: 1 1 35%;
      order: 3;
    }

    .highlight {
      font-weight: $font-weight-bold;
    }
  }

  .section-subheading {
    position: relative;
    margin: 0 0 40px;
    padding-bottom: 30px;
    font-size: 1.1875rem;

    &:after {
      @include position(absolute, null null 0 0);
      width: 22px;
      height: 2px;
      content: " ";
    }
  }

  section {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-actions {
    [type=submit] {
      margin-top: 40px;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      display: block;
      font-size: 1.1875rem;
    }
  }

  label[for="order_user_subscribed"] {
    font-size: 0.8125rem;
    opacity: 0.85;
  }

  .summary-view {
    margin-bottom: 0;

    th,
    td {
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}
