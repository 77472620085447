.m-promotions {
  &__wrapper {
    @media screen and (min-width: $grid-float-breakpoint) {
      @include clearfix;
    }

    @media screen and (min-width: $screen-xlg-min) {
      margin-right: -80px;
      margin-left: -80px;
    }
  }

  &__ornament {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      float: left;
      width: 52%;
    }
  }

  &__content {
    margin-top: 50px;
    margin-bottom: 80px;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: 150px;
      padding-top: 50px;
      padding-right: 50px;
      padding-left: 30px;
      float: left; // Required for Splide to work correctly inside flex element.
      width: 48%;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 70px;
      padding-left: 70px;
    }
  }

  &__indicators {
    margin-top: 15px;
  }
}
