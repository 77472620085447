.m-teasers {
  &__list {
    position: relative;
    margin: -50px auto 65px;
    display: flex;
    align-items: stretch;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    // Firefox 54.0.1 - layout bugfix - START
    float: left;
    width: 100%;
    // Firefox 54.0.1 - layout bugfix  - STOP

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: -100px;
    }
  }

  &__list-item {
    position: relative;
    width: 100%;
    z-index: $zindex-ui;
    color: inherit;

    @media screen and (min-width: $screen-xs-min) {
      width: 50%;
    }

    &--action {
      z-index: abs($zindex-ui - 1);
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    text-align: left;
    height: 100%;
    min-height: 200px;
    text-transform: uppercase;
    transform: translateZ(0);

    @media screen and (min-width: $grid-float-breakpoint) {
      min-height: 255px;
    }

    &,
    &:hover,
    &:focus {
      text-decoration: none;
      transition: transform $base-duration;
    }

    &:hover,
    &:focus {
      .m-teasers__item-title {
        transform: scale(1.05);
      }
    }

    &:focus {
      outline: $outline-default;
      outline-offset: -2px;
      @include tab-focus();
    }

    &--action {
      position: relative;
      overflow: hidden;
      color: inherit;
      text-transform: none;

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-right: -22px;
      }

      &:before {
        @include position(absolute, null null -0.14em -0.5em);
        font-size: 10rem;
        font-weight: $font-weight-bold;
        line-height: 1;
        color: $indicator-color;
        content: attr(data-title);

        @media screen and (min-width: $grid-float-breakpoint) {
          font-size: 16rem;
        }
      }
    }
  }

  &__item-image {
    @include position(absolute, 0 0 0 0);
    background-position: 50% 50%;
    background-size: cover;
    transition: all $base-duration;
    transform: translateZ(0);
  }

  &__item-title {
    position: relative;
    margin: 0.6em 0.7em;
    width: 100%;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    color: inherit;
    line-height: 1.1;
    word-wrap: break-word;
    hyphens: auto;
    transition: transform $base-duration;
    transform-origin: left bottom;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin: 1em;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 3rem;
    }

    &--image {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin: 0.6em 0.7em;
        font-size: 3rem;
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: 4.1875rem;
      }
    }
  }
}
