.m-nav {
  &__list {
    margin-bottom: 0;
    margin-left: -5px;
    padding-left: 0;
    list-style: none;
  }

  &__list-item {
    padding-left: 5px;
    display: inline-block;
    text-transform: uppercase;
  }

  &__item {
    display: block;
    padding: 5px 10px 5px 0;
    color: inherit;
    border-bottom: 2px solid transparent;

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 15px;
    }

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
      border-bottom: 2px solid;
    }
  }

  &__dropdown-list {
    font-weight: normal;
  }

  &__dropdown-item {
    &.is-active {
      background-color: $dropdown-link-hover-bg;
    }
  }
}
